<template>
  <v-container fluid class="mx-0 my-0 d-flex ind">
    <!--    <v-container class="back"></v-container>-->
    <v-img
      style="position: absolute; right: 5px; bottom: 0px"
      width="1300px"
      src="../../assets/PNG/bg_negativa.png"
    >
    </v-img>

    <v-layout row class="align-content-center">
      <v-flex xs12 md6 sm8 lg4>
        <v-card style="border-radius: 0px 50px 50px 0px" elevation="4">
          <v-card-title> </v-card-title>
          <v-form ref="form" v-model="validCad" lazy-validation>
            <v-card-actions class="px-2">
              <v-row>
                <div style="left: 0px">
                  <v-img
                    src="../../assets/SVG/monitora_logo_color.svg"
                    contain
                    max-height="100"
                    max-width="auto"
                  ></v-img>
                </div>
                <v-col cols="12">
                  <h3>Recuperar senha de acesso</h3>
                </v-col>

                <v-col cols="12" sm="12" md="12" xs="12" class="p-0">
                  <v-text-field
                    label="E-mail"
                    color="blue"
                    hide-details="auto"
                    v-model="formCad.email"
                    :rules="emailRules"
                    
                    prepend-inner-icon="mdi-email"
                    background-color="#dcf1ff"
                    solo
                    required
                    filled
                    rounded
                    @keypress.enter="recuperarAcesso()"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-actions class="pb-2 px-2">
              <v-row justify="center" style="margin-bottom: 15px; margin-top: 15px">
            
                  <v-btn
                    :to="{ name: 'login' }"
                    large
                    color="#FFDD00"
                    style="margin-right: 15px"
                  >
                    <img src="../../assets/PNG/reply_black_24dp.png" />
                    <span class="blue-btn-text"> Voltar</span>
                  </v-btn>
               
                  <v-btn
                    :disabled="!validCad"
                    :loading="loading"
                    @click="recuperarAcesso"
                    color="#0052A0"
                        large
                    class="amber--text "
                  >
                    <img src="../../assets/PNG/forward_black_24dp.png" />
                    Enviar
                  </v-btn>
               
              </v-row>
            </v-card-actions>
          </v-form>

          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            absolute
            top
            color="red darken-4"
            elevation="24"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                X
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import helper from "../../mixins/helper";

export default {
  components: {},
  mixins: [helper],
  data: () => ({
    show1: false,
    loading: false,
    snackbar: false,
    text: "",
    timeout: 2000,
    formCad: {
      email: null,
      password: "",
    },
    senhaRules: [
      (v) => !!v || "Senha é obrigátoria",
      (v) => v.length >= 8 || "Mínimo de 8 caracteres",
    ],
    emailRules: [
      (v) => !!v || "E-mail obrigatório",
      (v) => /.+@.+\..+/.test(v) || "E-mail informado não é válido",
    ],
  }),
  created() {},
  mounted() {},

  methods: {
    recuperarAcesso() {
      this.loading = true;
      this.$http
        .post(`recuperaracesso`, {
          email: this.formCad.email,
        })
        .then((response) => {
          console.log(response);

          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            background: "#4BB543",
            timer: 3000,

            title:
              '<span style="color:#FFF; font-family: Montserrat">Email para recuperação de senha enviado.</span>',
          });
          this.loading = false;
          this.$router.push({ name: "login" });
        })
        .catch(function (error) {
          this.loading = false;
          console.log(error);

          this.$swal({
            toast: true,
            position: "cebter",
            showConfirmButton: false,
            background: "#ff0000",
            timer: 3000,

            html: '<span style="color:#FFF">Não foi possível localizar e-mail de acesso.</span>',
          });
        });
    },
  },

  watch: {},
};
</script>




<style>
.back {
  background-color: #f27c00;
  height: 100%;
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100% !important;
  z-index: 0;
}
h3 {
  font-family: "Montserrat";
  color: #0052a0;
}
.v-card__title{
  background-color: transparent;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: inset 0px 3px 6px #00000029;
  border: 1px solid #00000000;
  height: 50px;
  color: #f27c00;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 45px;
  width: 150px;

  font-weight: 600;
  background-color: #2778c4;
  color: #ffd400;
  font-size: 1.0625em;
}

.rounded-card {
  border-radius: 50px;
}

.v-input__icon--append .v-icon {
  color: #0052a0;
}
.v-input__icon--prepend-inner .v-icon {
  color: #0052a0;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 18px;
  font-family: "Montserrat";
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  font-size: 13px;
  color: #0052a0;
  font-family: "Montserrat";
}
.blue-btn-text {
  color: #0052a0;
  font-weight: bold;
}
.blue-btn-text {
  color: #0052a0;
  font-weight: bold;
}
.ind {
  height: 100% !important;
}
.color-yellow {
  background: #ffd400;
}

.color-yellow-light {
  background: #ffdd00;
}
.title {
  color: #656565;
}
.v-btn {
  border-radius: 30px;
  font-family: "Montserrat";
}

.color-yellow-orange {
  background: #f27c00;
  background: -moz-linear-gradient(top, #f27c00 0%, #ffa900 100%);
  background: -webkit-linear-gradient(top, #f27c00 0%, #ffa900 100%);
  background: linear-gradient(to bottom, #f27c00 0%, #ffa900 100%);
}
.no-padding {
  padding: inherit !important;
}
</style>
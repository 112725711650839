<template>
  <v-container fluid class="mx-0 my-0 d-flex ind">
    <!--    <v-container class="back"></v-container>-->

    <v-layout row class="justify-center align-content-center">
      <v-flex xs12 md10 sm12 lg6>
        <v-card class="mx-auto rounded-lg" elevation="4">
          <v-card-title class="font-weight-bold color-yellow-light no-padding">
            <v-img
              src="../../assets/stonenegativa.svg"
              contain
              max-height="100"
              max-width="auto"
            ></v-img
          ></v-card-title>
          <v-card-title class="font-weight-bold title pt-14 pb-7 px-8">
            <h2>Recuperar dados de Acesso</h2></v-card-title
          >
          <v-form ref="form" v-model="validCad" lazy-validation>
            <v-card-actions class="px-2">
              <v-row>
                <v-col cols="12" sm="12" md="12" xs="12" class="p-0">
                  <v-text-field
                    label="E-mail"
                    color="orange"
                    outlined
                    hide-details="auto"
                    v-model="formCad.email"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" class="p-0">
                  <v-text-field
                    v-model="formCad.password"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    label="Nova senha"
                    outlined
                    required
                    :rules="senhaRules1"
                    @click:append="show2 = !show2"
                    color="orange"
                  ></v-text-field>
                  <div v-html="forcaSenha" />
                </v-col>

                <v-col cols="12" sm="12" md="12" class="p-0">
                  <v-text-field
                    v-model="formCad.passwordConfirm"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    label="Confirmar Senha"
                    outlined
                    required
                    :rules="senhaRules1"
                    @click:append="show3 = !show3"
                    color="orange"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-actions class="pb-2 px-2">
              <v-col cols="6" sm="6" md="6" xs="6">
                <v-btn
                  :disabled="!validCad"
                  :loading="btnloading"
                  @click="recuperarAcesso"
                  class="orange white--text font-weight-bold pa-7"
                >
                  Alterar

                  <v-icon dark>mdi-arrow-right-circle </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" sm="6" md="6" xs="6">
                <v-btn
                  :to="{ name: 'login' }"
                  class="gray white--black font-weight-bold pa-7"
                >
                  Tela de Login
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-form>

          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            absolute
            top
            color="red darken-4"
            elevation="24"
          >
            {{ text }}

            <template v-slot:action="{ attrs }">
              <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
              >
                X
              </v-btn>
            </template>
          </v-snackbar>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import helper from "../../mixins/helper";

export default {
  components: {},
  mixins: [helper],
  data: () => ({
    snackbar: false,
    show1: false,
    show2: false,
    show3: false,
    token: null,

    text: "",
    timeout: 2000,
    btnloading: false,
    formCad: {
      email: null,
      password: null,
      passwordConfirm: null,
      token: null,
    },
    senhaRules1: [
      (v) => !!v || "Senha é obrigatória",
      (v) =>
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(v) ||
        "A senha deve conter letras maiúsculas, letras minúsculas, números e caracteres especiais(@,$,&...)",
    ],
  }),
  created() {
    this.token = this.$route.query.token;

    this.formCad.email = this.$route.query.email;
  },
  mounted() {},

  methods: {
    async recuperarAcesso() {
      this.btnloading = true;

      if (this.token == null) {
        await this.$swal({
          toast: true,
          position: "bottom",
          showConfirmButton: false,
          background: "#ff0000",
          timer: 3000,

          title: "Erro",
          text: '<span style="color:#FFF">Token de recuperação não informado</span>',
        });
      } else {
        console.log(this.formCad);
        this.$http
          .post(`resetarsenha`, {
            email: this.formCad.email,
            password_confirmation: this.formCad.passwordConfirm,
            password: this.formCad.password,
            token: this.token,
          })
          .then((response) => {
            console.log(response);
            this.btnloading = false;
            this.$swal({
              toast: true,
              position: "bottom",
              showConfirmButton: false,
              background: "#4BB543",
              timer: 3000,

              title:
                '<span style="color:#FFF">Senha alterada com sucesso</span>',
            });
            this.$router.push({ name: "login" });
          })
          .catch(function () {
            //console.log(error);
            this.btnloading = false;
            this.$swal({
              toast: true,

              showConfirmButton: false,


              timer: 3000,
              background: "#ff0000",
              title:
                '<span style="color:#FFF">Não foi possível recuperar senha</span>'
            });
          });
      }
    },
  },

  watch: {},
};
</script>

<style>
.back {
  background-color: #f27c00;
  height: 100%;
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100% !important;
  z-index: 0;
}
.v-card__title{
  background-color: transparent;
}

.swal2-html-container {
  font-family: "Montserrat";

  font-weight: 600;
}
.ind {
  height: 100% !important;
}
.color-yellow {
  background: #ffd400;
}

.color-yellow-light {
  background: #ffdd00;
}
.title {
  color: #656565;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 45px;
  width: 150px;

  font-weight: 600;
  background-color: #2778c4;
  color: #ffd400;
  font-size: 1.0625em;
}
.color-yellow-orange {
  background: #f27c00;
  background: -moz-linear-gradient(top, #f27c00 0%, #ffa900 100%);
  background: -webkit-linear-gradient(top, #f27c00 0%, #ffa900 100%);
  background: linear-gradient(to bottom, #f27c00 0%, #ffa900 100%);
}
.no-padding {
  padding: inherit !important;
}
</style>